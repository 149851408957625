import React, { useState } from 'react'
import{ Navigate, Route, Routes as __NewsletterRoute__, } from "react-router-dom";
import ViewNewsletter from './pages/ViewNewsletter';
import { NewsletterContext } from './NewsletterContext';
import { newsletterState } from './newsletterState';
import CreateNewsletter from './pages/CreateNewsletter';
import EditNewsletter from './pages/EditNewsLetter';

const NewsletterRoute = () => {

 const [ newsletter, setNewsletter ] = useState( newsletterState )

  return (
    <section className='bg-white p-3'>
      <NewsletterContext.Provider value = { { newsletter, setNewsletter } } > 
       <__NewsletterRoute__>
           <Route path='/' element= { <Navigate to={'/admin/newsletter/view'}  /> } />
           <Route path='/view' element= { <ViewNewsletter /> } />
           <Route path='/create' element= { <CreateNewsletter /> } />
           <Route path='/edit' element= { <EditNewsletter /> } />
       </__NewsletterRoute__>
       </NewsletterContext.Provider>
    </section>
  )
}

export default NewsletterRoute