import React, { useState } from 'react'

const Pagination = ({ newsletter_emails, getNewsletterMutation }) => {
  
 const [currentPage, setCurrentPage ] = useState(1)
 
 const handlePagination = link => {
    const  url = link?.url
     if(url !== null ){
        const pageNo = url.slice(url.lastIndexOf('=') + 1, url.length)
        //const filters = {pageNo, per_page: perPage } 
         setCurrentPage(pageNo)
         getNewsletterMutation.mutate(`&page=${pageNo}`)
     }
 }
  

  return (
    <>  
    <div className='md:flex justify-between md:px-5 mt-4'>
         <div>
            <nav aria-label="Page navigation example w-full">
                <ul style={{ whiteSpace: 'nowrap'}} class="list-style-none overflow-x-auto  block py-1 items-center overflow-x-auto w-full">
                   {newsletter_emails?.data?.links.map( (link, index) => {
                         if(( index === 0) || (index === newsletter_emails?.data?.links?.length - 1) ){
                           return  <li 
                           key={link?.label}
                           className='inline-block'
                           onClick={ () => handlePagination(link)}
                            >
                            <a
                               className="pointer-events-none relative block rounded-full bg-transparent px-3 py-1.5 text-lg text-neutral-500 transition-all duration-300 dark:text-neutral-400"
                               dangerouslySetInnerHTML={{ __html: link?.label }}
                             />
                          </li> 
                         }
                        else if(link?.active){
                           return(
                             <li aria-current="page" 
                                 className='inline-block' 
                                 onClick={ () => handlePagination(link)}
                             >
                                <a
                                    class="relative block rounded-full bg-gray-300 px-3 py-1.5 text-lg font-medium  transition-all duration-300"
                                    href="#!"
                                    > {link?.label}
                                    <span
                                    class="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                                    >( {link?.label} )</span>
                                </a>
                             </li>
                           )
                        } 
                        else {
                            return(
                             <li className='inline-block' onClick={() => handlePagination(link)}>
                                <a
                                 className="relative block rounded-full bg-transparent px-3 py-1.5 text-lg text-neutral-600 transition-all duration-300 hover:bg-neutral-100  dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
                                  href="#!"
                                >{link?.label}</a>
                              </li>
                            )
                        }
                      })}
                   </ul>
                </nav>
            <div>
            </div>
        </div>
    </div>
  </>
  )
}

export default Pagination