import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import logo from "../../images/TestAssessify.png";
import { api } from "../../api";
import { useMutation } from "react-query";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { mutateAsync } = useMutation(api.resetPassword);

  const resetPasswordEmail = sessionStorage.getItem("resetPasswordEmail");

  console.log(resetPasswordEmail);

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    otp: Yup.string().required("O.T.P is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string().required(
      "Password confirmation is required"
    ),
  });

  const initValues = {
    otp: "",
    password: "",
    password_confirmation: "",
  };

  const handleSubmit = async (values) => {
    toast.loading("Processing...");

    const payload = { ...values, email: resetPasswordEmail };
    try {
      const data = await mutateAsync(payload);
      toast.remove();
      toast.success(data?.message, { duration: 5000 });
      setTimeout(() => {
        navigate("/");
      }, 1000);
      // console.log({ data });
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.error);
      // console.log(response);
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="bg-white p-10">
      <div className="w-full pb-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>

      <div className="container relative grid place-items-center mt-20 2xl:mt-0 2xl:fixed 2xl:h-screen ">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full  md:w-[600px] lg:w-[900px] rounded-xl p-10  shadow-2xl">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white lg:w-1/2 p-2 py-10">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0  lg:px-4">
                <p className="text-3xl text-darkBlue pt-4 mb-2 font-semibold">
                  Reset Password
                </p>
                <p className="text-base font-normal text-slate-5">
                  Please create a new password
                </p>
                <Formik
                  initialValues={initValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form
                    style={{
                      border: "1px solid #ccc",
                      width: "100%",
                      margin: "20px auto 0 auto",
                      padding: "10px",
                      borderRadius: "7px",
                      boxShadow: "0 0 5px 0 #ccc",
                    }}
                  >
                    <div className="flex flex-col">
                      <label htmlFor="otp" style={{ fontSize: "12px" }}>
                        O.T.P
                      </label>
                      <div className="mb-4 relative w-full">
                        <Field
                          type="text"
                          id="otp"
                          name="otp"
                          className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen  w-full pl-2 focus:pl-4"
                        />
                      </div>
                      <span className="h-8 mt-1 mb-2">
                        <ErrorMessage
                          name="password"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="password" style={{ fontSize: "12px" }}>
                        Password
                      </label>
                      <div className="mb-4 relative w-full">
                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen  w-full pl-2 focus:pl-4"
                        />
                        <span
                          className="absolute right-2 text-slate-400 cursor-pointer"
                          style={{ top: "8px" }}
                          onClick={(e) => handleShowPassword(e)}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                      <span className="h-8 mt-1 mb-2">
                        <ErrorMessage
                          name="password"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="password_confirmation"
                        style={{ fontSize: "12px" }}
                      >
                        Confirm Password
                      </label>
                      <div className="mb-4 relative w-full">
                        <Field
                          type={showConfirmPassword ? "text" : "password"}
                          id="password_confirmation"
                          name="password_confirmation"
                          className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen  w-full pl-2 focus:pl-4"
                        />
                        <span
                          className="absolute right-2 text-slate-400 cursor-pointer"
                          style={{ top: "8px" }}
                          onClick={(e) => handleShowConfirmPassword(e)}
                        >
                          {showConfirmPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                      <span className="h-8 mt-1 mb-2">
                        <ErrorMessage
                          name="password_confirmation"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </span>
                    </div>
                    <button
                      type="submit"
                      style={{ fontSize: "12px" }}
                      className="border border-customGreen text-white w-full py-1 bg-customGreen rounded-md"
                    >
                      Submit
                    </button>
                  </Form>
                </Formik>
                <div className="py-4  text-center">
                  <p className="text-xl">
                    Click here to &nbsp;
                    <Link to="/" className="font-semibold underline">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
