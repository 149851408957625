import React, { useRef } from "react";
import "./Table.css";

function Table({ children }) {
  const table = useRef();

  return (
    <React.Fragment>
      <table ref={table} className={"Parent_Style"}>
        {children}
      </table>
    </React.Fragment>
  );
}

export default Table;
