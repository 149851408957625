import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import background from "../../images/cardbase.png";
import logo from "../../images/TestAssessify.png";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { api } from "../../api";
import { UserContext } from "../../context/UserContext";
import CryptoJS from "crypto-js";

export function SigninPage() {
  const { register, handleSubmit } = useForm();
  const navigation = useNavigate();
  const { mutateAsync: onLogin } = useMutation(api.login);
  const { setValue } = useContext(UserContext);
  const key = process.env.REACT_APP_SECRET_KEY;

  const styles = {
    container: {
      width: "35%",
      margin: "0 auto",
      padding: "2%",
      border: "2px grey solid",
    },
    input: {
      width: "100%",
      border: "1px grey solid",
      padding: "1%",
    },
  };

  function greetTime() {
    const today = new Date();
    const hourOfDay = today.getHours(); //The current hour of today
    if (hourOfDay > 11 && hourOfDay <= 16) {
      return "afternoon";
    } else if (hourOfDay > 16) {
      return "evening";
    } else {
      return "morning";
    }
  }

  const onSubmit = async (payload) => {
    try {
      toast.loading("Logging in...");
      const data = await onLogin({ ...payload, account_type: "admin" });
      setValue((prev) => {
        return { ...prev, user: data.data || null };
      });
      const encryptedToken = CryptoJS.AES.encrypt(
        data.data.token,
        key
      ).toString();
      sessionStorage.setItem("userToken", encryptedToken);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
      sessionStorage.setItem(
        "admin-role",
        hashSync(data?.data?.designation_slug, 10)
      );
      setTimeout(() => {
        toast.remove();
        toast.success("Logged in successfully...");
        navigation("/admin");
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.error);
    }
  };

  return (
    <div
      className="flex flex-col justify-center w-full"
      style={{
        backgroundColor: "whitesmoke",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {/*<AdminHeader />*/}
      <div className="w-full p-3 h-14 bg-inherit sticky top-0">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-36 h-full object-contain"
          />
        </div>
      </div>
      <div
        className="flex flex-col justify-center md:flex-row md:justify-end items-center p-4 w-3/4 mx-auto shadow-md"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "90vh",
        }}
      >
        <form
          className="w-full md:w-1/3 md:mr-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex md:items-center justify-start w-full  overflow-hidden">
            <h1
              className="text-2xl md:w-3/4 object-contain font-medium"
              style={{ color: "oliveDrab" }}
            >
              Exam Monitor Sign in
            </h1>
          </div>
          <p className="my-4 text-baseFont text-slate-500">
            Good {greetTime()},
            <br /> Please sign into your account
          </p>
          <input
            className="block text-baseFont w-full border p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            type="email"
            {...register("email", {
              required: true,
            })}
            placeholder="Enter your email"
          />
          <input
            className="block w-full text-baseFont border p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            type="password"
            required
            {...register("password", {
              required: true,
            })}
            placeholder="Enter your password"
          />
          <span className="text-slate-700 text-baseFont">
            <input type="checkbox" /> Keep me signed in
          </span>
          <br />
          <input
            id="submit"
            type="submit"
            value="Sign in"
            className="hover:bg-green-500 hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer bg-green-600 text-white py-2"
          />
          {/* <span style={{ fontSize: "12px", fontWeight: "500" }}>
            You do not have an account?{" "}
            <Link
              to={"/register"}
              className="hover:underline text-baseFont text-customGreen"
            >
              Click to register
            </Link>
          </span> */}
        </form>
      </div>
    </div>
  );
}

export default SigninPage;
