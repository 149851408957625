import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import logo from "../assets/testAssessify_small.png";
import { api } from "../api";
import { useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCustomToast } from "../components/customtoast/CustomToastProvider";
import VerifyEmail from "./VerifyEmail";
import PageLayout from "./admin/components/PageLayout/PageLayout";

const validationSchema = Yup.object({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("last Name is required"),
  client_name: Yup.string().required("Role is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  password_confirmation: Yup.string().required("Password is required"),
});

const initValues = {
  first_name: "",
  last_name: "",
  client_name: "",
  email: "",
  password: "",
};

const Register = () => {
  const { mutateAsync } = useMutation(api.createClient);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [modal, showModal] = useState(false);

  const customToast = useCustomToast();

  const handleSubmit = async (values) => {
    toast.loading("Processing...");
    try {
      const data = await mutateAsync(values);
      sessionStorage.setItem("client_email", values?.email);
      toast.remove();
      toast.success(data?.message, { duration: 5000 });
      setTimeout(() => {
        handleModal();
      }, 1000);
    } catch ({ response }) {
      console;
      toast.remove();
      customToast.error(response?.data?.message);
      // console.log(response?.data?.message);
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleModal = () => {
    showModal(!modal);
  };

  return (
    <PageLayout backBtn={true}>
      {modal && <VerifyEmail handleModal={handleModal} />}
      <div className="w-full flex items-center justify-center mt-3">
        <img src={logo} alt="logo" className="h-20 w-75" />
      </div>

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form
          style={{
            border: "1px solid #ccc",
            maxWidth: "500px",
            margin: "20px auto 0 auto",
            padding: "10px",
            borderRadius: "7px",
            boxShadow: "0 0 5px 0 #ccc",
          }}
        >
          <div className="flex flex-col">
            <label htmlFor="first_name" style={{ fontSize: "12px" }}>
              First Name
            </label>
            <Field
              type="text"
              id="first_name"
              name="first_name"
              className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen pl-2 focus:pl-4"
            />
            <span className="h-8 mt-1 mb-2">
              <ErrorMessage
                name="first_name"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <label htmlFor="last_name" style={{ fontSize: "12px" }}>
              Last Name
            </label>
            <Field
              type="text"
              id="last_name"
              name="last_name"
              className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen pl-2 focus:pl-4"
            />
            <span className="h-8 mt-1 mb-2">
              <ErrorMessage
                name="last_name"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <label htmlFor="client_name" style={{ fontSize: "12px" }}>
              Name Of Organization
            </label>
            <Field
              type="text"
              id="client_name"
              name="client_name"
              className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen pl-2 focus:pl-4"
            />
            <span className="h-8 mt-1 mb-2">
              <ErrorMessage
                name="client_name"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <label htmlFor="email" style={{ fontSize: "12px" }}>
              Email
            </label>
            <Field
              type="email"
              id="email"
              name="email"
              className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen pl-2 focus:pl-4"
            />
            <span className="h-8 mt-1 mb-2">
              <ErrorMessage
                name="email"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <label htmlFor="password" style={{ fontSize: "12px" }}>
              Password
            </label>
            <div className="mb-4 relative w-full">
              <Field
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen  w-full pl-2 focus:pl-4"
              />
              <span
                className="absolute right-2 text-slate-400 cursor-pointer"
                style={{ top: "8px" }}
                onClick={(e) => handleShowPassword(e)}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
            <span className="h-8 mt-1 mb-2">
              <ErrorMessage
                name="email"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <label htmlFor="password_confirmation" style={{ fontSize: "12px" }}>
              Confirm Password
            </label>
            <div className="mb-4 relative w-full">
              <Field
                type={showConfirmPassword ? "text" : "password"}
                id="password_confirmation"
                name="password_confirmation"
                className="border border-slate-300 focus:ring-0 outline-none duration-200 focus:border-customGreen  w-full pl-2 focus:pl-4"
              />
              <span
                className="absolute right-2 text-slate-400 cursor-pointer"
                style={{ top: "8px" }}
                onClick={(e) => handleShowConfirmPassword(e)}
              >
                {showConfirmPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
            <span className="h-8 mt-1 mb-2">
              <ErrorMessage
                name="email"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </span>
          </div>
          <button
            type="submit"
            style={{ fontSize: "12px" }}
            className="border border-customGreen text-white w-full py-1 bg-customGreen rounded-md"
          >
            Submit
          </button>
        </Form>
      </Formik>
      <span
        className="w-full flex items-center justify-center gap-1 mt-1"
        style={{ fontSize: "12px", fontWeight: "500" }}
      >
        O.T.P Received?
        <button
          onClick={handleModal}
          className="hover:underline text-baseFont text-customGreen"
        >
          Click to verify
        </button>
      </span>
    </PageLayout>
  );
};

export default Register;
