import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import FileSaver from "file-saver";
import { api } from "../../../api";
import { useMutation } from "react-query";

const ResetModal = ({ setShowModal, assessments }) => {
  const backdropRef = useRef(null);
  const [assessmentID, setAssessmentID] = useState(null);
  const { mutateAsync } = useMutation(api.resetExam);

  const handleAssessmentID = (e) => {
    setAssessmentID(e.target.value);
  };

  const handleReset = async (e) => {
    if (!assessmentID) {
      toast.error("Select an assessment to reset");
    }
    const userGroupID = sessionStorage.getItem("user_group_id");
    toast.loading("Processing...");
    try {
      const payload = {
        user_group_id: userGroupID,
        assessment_id: assessmentID,
      };
      const data = await mutateAsync(payload);
      console.log(data);
      toast.remove();
      toast.success();
    } catch (err) {
      console.log(err);
      toast.remove();
      toast.error("Unable to reset exam");
    }
  };

  const handleModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
      ref={backdropRef}
    >
      <div
        className="bg-transparent rounded-lg w-full mx-auto p-7 bg-white"
        style={{ maxWidth: "700px" }}
      >
        <div className="flex flex-col">
          <label htmlFor="score_style" className="text-baseFont">
            Select Assessment
          </label>
          <select
            id="score_style"
            name="score_style"
            className="border border-slate-300 text-baseFont focus:ring-0 outline-none duration-200 focus:border-customGreen pl-2 focus:pl-4"
            onChange={handleAssessmentID}
          >
            <option selected disabled>
              Choose below
            </option>
            {assessments.length &&
              assessments?.map((assessment) => (
                <option key={assessment.id} value={assessment.id}>
                  {assessment?.title}
                </option>
              ))}
          </select>
        </div>
        <div className="flex items-center justify-between gap-2">
          <button
            className="flex-1 mt-4 rounded-md text-baseFont bg-customOrange text-white py-2"
            onClick={handleModal}
          >
            Close
          </button>
          <button
            className="flex-1 mt-4 rounded-md text-baseFont bg-customGreen text-white py-2"
            onClick={handleReset}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetModal;
