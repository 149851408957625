import React, { useEffect } from "react";
import StraightIcon from "@mui/icons-material/Straight";
import { api } from "../../../api";
import { useQuery } from "react-query";
import moment from "moment/moment";
function RecentActivities({ currentPeriod }) {
  const type = "client";
  const { data, refetch } = useQuery(
    "recent-activities",
    async () => {
      const data = await api.getRecentActivity(type, currentPeriod);
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
    }
  );

  // console.log({ data });

  useEffect(() => {
    refetch();
  }, [currentPeriod]);

  function formatTimeAgo(dateString) {
    const currentTime = moment();
    const activityTime = moment(dateString);
    const duration = moment.duration(currentTime.diff(activityTime));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes() % 60);
    return `${hours} hours ${minutes} minutes ago`;
  }

  // console.log({ data });

  return (
    <div>
      <div>
        <h4 className="">Recent Activity</h4>
        <div
          style={{
            height: "500px",
            overflowY: "scroll",
          }}
          className="w-full border border-slate-400 mb-1"
        >
          {/* <div className="flex space-x-24 items-center">
            <div className="flex gap-x-2 items-center">
              <div className="rotate-180 text-red-600">
                <StraightIcon />
              </div>
              <div className="text-xs">Muhammed Jamiu</div>
            </div>
            <div className="text-xs">13 minutes ago</div>
          </div> */}

          {data?.data?.data?.map((activity, id) => {
            return (
              <div className="w-full" key={id}>
                <div className="flex justify-start space-x-24 items-center">
                  <div className="w-2/3 flex gap-x-2 items-center">
                    <div className="text-green-600">
                      <StraightIcon />
                    </div>
                    <div className="text-xs">
                      <p className="text-xs">{activity.email}</p>
                      <p className="text-xs mt-1">
                        {`Location -> `}
                        {activity?.city_name}
                      </p>
                      <p className="text-xs mt-1">
                        {`IP Address -> `}
                        {activity?.ip_address}
                      </p>
                      <p className="text-xs mt-1">
                        {`Browser -> `}
                        {activity?.user_agent}
                      </p>
                    </div>
                  </div>
                  <div className=" w-1/2   text-xs">
                    {formatTimeAgo(activity.created_at)}
                  </div>
                </div>
                <hr className="my-2" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default RecentActivities;
