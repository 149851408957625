import { useState, useEffect } from "react";
import { useInterval } from "../../../hooks/useInterval";

const Timer = ({ refetch }) => {
  const [countdown, setCountdown] = useState(60); // Countdown in seconds

  // Custom hook for interval timer
  useInterval(() => {
    setCountdown((prevCountdown) =>
      prevCountdown > 0 ? prevCountdown - 1 : 0
    );
  }, 1000); // Run the interval every 1 second (1000ms)

  useEffect(() => {
    if (countdown === 0) {
      // Trigger the refetch() function when countdown reaches 0
      refetch();
      // Reset the countdown to 60 seconds after refetch
      setCountdown(60);
    }
  }, [countdown, refetch]);

  // Convert the remaining seconds into a formatted time string (mm:ss)
  const formattedTime = `${Math.floor(countdown / 60)
    .toString()
    .padStart(2, "0")}:${(countdown % 60).toString().padStart(2, "0")}`;

  return <div>{formattedTime}</div>;
};

export default Timer;
