import React, { useState } from 'react';
import OtpInput from 'react18-input-otp';
import { useMutation } from 'react-query';
import { api } from "../../../api/auth"
import { hashSync } from "bcryptjs";;
import toast from 'react-hot-toast';
import CryptoJS from "crypto-js";
import { useNavigate } from 'react-router-dom';

const OtpForm = () => {

    const [otp, setOtp] = useState('');
    const email = sessionStorage?.getItem('verification-email')
    const key = process.env.REACT_APP_SECRET_KEY;
    const navigate = useNavigate()

    const resendOtpMutation = useMutation(api.resendOtp, {
        onSuccess: data => {         
            toast.remove()
            toast.success(`We resend an otp to ${email}`)            
        },
        onMutate: () => {
           toast.loading('Sending Otp...')
        },
        onError: (error) => {
          toast.remove() 
          toast.error(error.response.data.message)        
        }
      })

      const verifyOtpMutation = useMutation(api.verifyEmail, {
        onSuccess: data => {         
            toast.remove()
            toast.success(`Successfully verified`)                
            const encryptedToken = CryptoJS.AES.encrypt(
                data.data.token,
                key
              ).toString();
              sessionStorage.setItem("isLoggedIn", true);
              sessionStorage.setItem("userToken", encryptedToken);
              sessionStorage.setItem("user", JSON.stringify(data?.data));
              sessionStorage.setItem(
                "admin-role",
                hashSync(data?.data?.designation_slug, 10)
              );       
              sessionStorage.setItem('first-time-login', 1)
             setTimeout( () => navigate('/admin/billings/billing-plans') , 500)
        },
        onMutate: () => {
           toast.loading('verifying otp...')
        },
        onError: (error) => {
          toast.remove() 
          toast.error(error.response.data.message)        
        }
      })

    const handleChange = (enteredOtp) => {
      setOtp(enteredOtp);
    };

    const resendOtp = () => {
        resendOtpMutation.mutate({email})
    }

    const verifyOtp = () => {
        verifyOtpMutation.mutate({ email, otp })
    }


 return (
    <div>
        <h1 className='text-3xl text-customGreen'>
          You Are Almost Done !
        </h1>
        <p className='mt-2 mb-5 text-lg'>
            An email has been sent to <b className='text-lg'>
               {email}
            </b> with 
            a verification code, enter the code to complete your registeration .
        </p>
         <div className='flex justify-center w-full pb-5'>
            <OtpInput 
                value={otp} onChange={handleChange} 
                numInputs={6} separator={<span className='font-bold px-2'>-</span>} 
                onSubmit={ () => {
                    verifyOtp()
                } }
                inputStyle={{
                    fontSize: 30,
                    width: 50
                }}
            />
         </div>
         <p className='pt-3 text-lg text-left'>
            Din't recieve O.T.P?
            <button
              onClick={resendOtp}
              disabled={resendOtpMutation.isLoading}
              className="ml-4 underline text-xl text-baseFont hover:cursor-pointer text-customGreen"
            >
             Resend
            </button>
         </p>
         <div className='flex justify-end pt-3'>
            <button 
                onClick={verifyOtp}
                disabled={verifyOtpMutation.isLoading} 
                className='bg-customBlue text-white mr-5 py-3 px-16 rounded-lg text-md'
            > 
                Verify Otp <i className='bi bi-arrow-right'></i>
            </button>
        </div>
     </div>
    )      
}

export default OtpForm