import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { api } from "../../api";
import { useNavigate } from "react-router-dom";

const ForgotPasswordModal = ({ hideModal }) => {
  const backdropRef = useRef(null);
  const [otpStatus, setOtpStatus] = useState(false);
  const { mutateAsync: ForgetPassword } = useMutation(api.forgotPassword);
  const { mutateAsync: VerifyEmail } = useMutation(api.verifyEmail);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    toast.loading("Processing...");
    try {
      const data = await ForgetPassword({ email });
      // console.log({ data });
      toast.remove();
      toast.success(data?.message);
      // setOtpStatus(true);
      hideModal(true);
      sessionStorage.setItem("resetPasswordEmail", email);
      navigate("/reset-password");
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.error);
    }
  };

  const handleOTP = async (e) => {
    e.preventDefault();
    toast.loading("Processing...");
    try {
      const data = await VerifyEmail({ otp, email });
      // console.log({ data });
      toast.remove();
      toast.success(data?.message);
      hideModal(true);
      sessionStorage.setItem(
        "resetPasswordDetails",
        JSON.stringify({ email, otp })
      );
      navigate("/reset-password");
    } catch ({ response }) {
      // console.log(response);
      toast.remove();
      toast.error(response?.data?.error);
    }
  };

  const handleBackdropClick = (event) => {
    // Check if the backdrop element was clicked (not its children)
    if (event.target === backdropRef.current) {
      hideModal();
    }
  };

  if (otpStatus) {
    return (
      <div
        className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
        ref={backdropRef}
        onClick={handleBackdropClick}
      >
        <div
          className="bg-transparent rounded-lg w-full mx-auto p-7 bg-white"
          style={{ maxWidth: "500px" }}
        >
          <form className="w-full" onSubmit={handleOTP}>
            <p>Enter your O.T.P</p>
            <input
              className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
              type="text"
              placeholder="Enter your O.T.P"
              value={otp}
              defaultValue={""}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
            <input
              id="submit"
              type="submit"
              value="SUBMIT O.T.P"
              className="hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer bg-customBlue text-white py-2"
            />
          </form>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
      ref={backdropRef}
      onClick={handleBackdropClick}
    >
      <div
        className="bg-transparent rounded-lg w-full mx-auto p-7 bg-white"
        style={{ maxWidth: "500px" }}
      >
        <form className="w-full" onSubmit={onSubmit}>
          <p>Email</p>
          <input
            className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            placeholder="Enter your email"
          />
          <input
            id="submit"
            type="submit"
            value="Submit"
            className="hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer bg-customBlue text-white py-2"
          />
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
