export const adminPermissions = {
  "view-all-users": "view-all-users",
  "update-a-category": "update-a-category",
  "update-a-group": "update-a-group",
  "view-all-campaigns": "view-all-campaigns",
  "add-a-plan": "add-a-plan",
  "view-all-plans": "view-all-plans",
  "update-a-user": "update-a-user",
  "add-a-section": "add-a-section",
  "view-analytics": "view-analytics",
  "delete-a-candidate": "delete-a-candidate",
  "view-all-roles": "view-all-roles",
  "view-all-permissions": "view-all-permissions",
  "update-a-section": "update-a-section",
  "update-a-designation": "update-a-designation",
  "view-all-candidates": "view-all-candidates",
  "add-a-candidate": "add-a-candidate",
  "update-a-assessment": "update-a-assessment",
  "update-a-criteria": "update-a-criteria",
  "add-a-permission": "add-a-permission",
  "add-a-stage": "add-a-stage",
  "update-a-stage": "update-a-stage",
  "view-all-stages": "view-all-stages",
  "update-a-pipeline": "update-a-pipeline",
  "add-a-user": "add-a-user",
  "view-all-results": "view-all-results",
  "delete-a-pipeline": "delete-a-pipeline",
  "add-a-result": "add-a-result",
  "add-a-designation": "add-a-designation",
  "view-all-groups": "view-all-groups",
  "add-a-pipeline": "add-a-pipeline",
  "delete-a-assessment": "delete-a-assessment",
  "delete-a-designation": "delete-a-designation",
  "update-a-role": "update-a-role",
  "add-a-category": "add-a-category",
  "add-a-group": "add-a-group",
  "delete-a-campaign": "delete-a-campaign",
  "add-a-assessment": "add-a-assessment",
  "view-all-categories": "view-all-categories",
  "top-up-plan": "top-up-plan",
  "view-all-sections": "view-all-sections",
  "view-all-assessments": "view-all-assessments",
  "add-a-question": "add-a-question",
  "view-all-designations": "view-all-designations",
  "delete-a-user": "delete-a-user",
  "view-all-pipelines": "view-all-pipelines",
  "update-a-plan": "update-a-plan",
  "add-a-criteria": "add-a-criteria",
  "update-a-permission": "update-a-permission",
  "update-a-campaign": "update-a-campaign",
  "view-all-criterias": "view-all-criterias",
  "update-a-question": "update-a-question",
  "view-all-questions": "view-all-questions",
  "view-all-links": "view-all-links",
  "add-a-campaign": "add-a-campaign",
  "delete-a-group": "delete-a-group",
  "can-exports": "can-exports",
  "add-a-role": "add-a-role",
  "update-a-candidate": "update-a-candidate",
  "update-a-result": "update-a-result",
  "delete-a-role": "delete-a-role",
};
