import React, { useState } from "react";
import useWindowDimensions from "../../../../../hooks/useWindowSize";
import OverallResult from "./OverallResult";
import SectionWisePerformance from "./SectionWisePerformance";
import SecurityLog from "./SecurityLog";
import Attendance from "./Attendance";
import QuestionResponse from "./QuestionResponse";

const Exam = () => {
  const { width } = useWindowDimensions();

  return (
    <div>
      <h2 className="font-semibold text-base mb-6">Exam</h2>
      <div
        className={`w-full flex flex-col
           "items-start"
         ${width > 1100 ? "flex-row" : "flex-col"}`}
      >
        <Attendance />
        <OverallResult />
        <SectionWisePerformance />
        <SecurityLog />
        <QuestionResponse />
      </div>
    </div>
  );
};

export default Exam;
