import React from "react";
import { BarLoader } from "react-spinners";

const Card = ({
  title,
  body,
  titleClassname,
  bodyClassName,
  className,
  spacing = true,
  isLoading,
}) => {
  return (
    <div
      className={`${className} min-w-[150px] flex items-center justify-center flex-col min-h-[100px] text-customBlue gap-2`}
    >
      <h1
        className={` ${
          titleClassname ? titleClassname : "text-sm font-semibold"
        }`}
        style={{ letterSpacing: spacing ? "3px" : 0 }}
      >
        {title}
      </h1>
      {isLoading ? (
        <div className="mt-3">
          <BarLoader size={150} color={"#f35b24"} />
        </div>
      ) : (
        <p className={`${bodyClassName ? bodyClassName : "text-4xl"}`}>
          {body ? body : "-"}
        </p>
      )}
    </div>
  );
};

export default Card;
