import React from 'react'

const UnAuthorized = ({text, className}) => {
  return (
    <div className={`${className} w-[400px] mx-auto pt-12`}>
        <h5 className='mt-2 text-center'>
            <span className='bi bi-exclamation-triangle text-[100px] text-red-500'></span>
        </h5>
       <p className='text-md text-center'>{text ? text : 'Sorry! you are not authorized to access this resource.'}</p>
    </div>
  )
}

export default UnAuthorized