import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const Error = ({ err }) => {
  return (
    <div
      className="w-full flex items-center justify-center gap-2"
      style={{ height: "300px", fontSize: "12px" }}
    >
      <FontAwesomeIcon icon={faCircleExclamation} />
      <p>Unable to get {err}, kindly reload page or contact site admin. </p>
    </div>
  );
};

export default Error;
