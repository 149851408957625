import React from 'react'
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import {ProjectContextProvider} from './components/Context/ProjectContext';
// import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

// const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <BrowserRouter>       
        <App />       
    </BrowserRouter>
  </React.StrictMode>
);
