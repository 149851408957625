import React, { useState, useEffect } from 'react'
import SearchTemplate from '../components/SearchTemplate'
import DeleteNewsletterModal from '../components/DeleteNewsletterModal';
import { Modal, Ripple, initTE } from "tw-elements";
import SelectGroupModal from '../components/SelectGroupModal';
import { Link } from 'react-router-dom';
import { api } from '../../../../../api';
import { useQuery, useMutation } from 'react-query';
import ScaleLoader_ from '../../../components/Loader/ScaleLoader';
import Pagination from '../components/Pagination';
 

const ViewNewsletter = () => {

  const [isActive, setIsActive ] = useState(1)
  const [newsletters, setNewsletters] = useState([])

  const { data, isLoading, isError, refetch} = useQuery(["get newsletter"],  () => {
     return api.getNewsletters()
   } );

   const getNewsletterMutation = useMutation( api.getNewsletters, {
    onSuccess : (data) => {
       setNewsletters(data)
    }
  })

   React.useEffect( () =>  {
    if(data){
      setNewsletters(data)
    }
} , [data])

  const showHistory = () => {
     //display email template history
     setIsActive(1)
     getNewsletterMutation.mutate('')
  }

  const showDraft = () => {
    //display draft email template 
    setIsActive(2)
    getNewsletterMutation.mutate('&status=draft')
  }

  const showPublished = () => {
    //display draft email template 
    setIsActive(3)
    getNewsletterMutation.mutate('&status=published')
  }

    useEffect( () => {
      initTE({ Ripple, Modal });
    }, [] )

  return (
    <section className='pt-3'> {( isLoading || getNewsletterMutation.isLoading ) && <ScaleLoader_ />}
        <div className='py-5 border border-gray-200  px-3 rounded-lg'>
            <h5 className='text-2xl'> 
                Newsletter  
            </h5>
            <div className='flex mt-3'>
                <button onClick={showHistory} className={`text-md  ${isActive === 1 ? 'text-gray-400 underline' : ''}`}>
                    All 
                </button>
                <button onClick={showDraft} className={`text-md ml-5 ${isActive === 2 ? 'text-gray-400 underline' : ''}`}>
                    Draft 
                </button>
                <button onClick={showPublished} className={`text-md ml-5 ${isActive === 3 ? 'text-gray-400 underline' : ''}`}>
                    Published 
                </button>
            </div>
        </div>
        <div className='py-5 border border-gray-200 mt-3 px-3 mt-4 rounded-lg'>
               <div className='md:flex justify-between items-center'>
                    <div className='w-[300px] mt-2 mb-3 md:mb-0'>
                          <SearchTemplate
                             getNewsletterMutation = {getNewsletterMutation}
                          />
                    </div>
                     <div>
                      <Link to={'/admin/newsletter/create'}
                       className='bi bi-plus-circle bg-customOrange text-md py-3 px-8 text-white rounded-lg'>
                          &nbsp;Create Newsletter
                      </Link>
                     </div>
               </div>
               <div className='mt-5'>
                   { newsletters?.data?.data.length ? newsletters?.data?.data?.map( (newsletter, id ) => {
                      return(
                        <div key={newsletter.id} className='border-t border-b border-gray-200 py-2  md:flex hover:cursor-pointer hover:bg-gray-100 hover:py-2 justify-between px-1 md:px-3 mb-5'>
                          <div className='flex'>
                              <span className='text-md'>{id + 1}.</span>
                              <span className='text-md text-gray-700 ml-5'>
                                 { `${new Date(newsletter.created_at).toLocaleDateString()} `}
                              </span>
                              <span className='text-md ml-5'>{newsletter.subject}</span>
                          </div>
                          <div className='flex mt-3 md:mt-0'>
                              <button 
                                  className='text-md text-customGreen bi bi-send'
                                  data-te-toggle="modal"
                                  data-te-target={`#send-${newsletter.id}`}
                                  data-te-ripple-init
                                  data-te-ripple-color="light"
                                >
                                  &nbsp; Send
                              </button>
                              <Link 
                                  onClick={ () => {
                                    sessionStorage.setItem('active-editor-content', JSON.stringify(newsletter))
                                  } }
                                  to={'/admin/newsletter/edit'}
                                   className='text-md ml-5 text-blue-500 bi bi-pencil-square'
                               >
                                  &nbsp; Edit
                              </Link>
                              <button 
                                 className='text-md text-red-500 ml-5 bi bi-trash'
                                 data-te-toggle="modal"
                                  data-te-target={`#delete-${newsletter.id}`}
                                  data-te-ripple-init
                                  data-te-ripple-color="light"
                                >
                                  &nbsp; Delete
                              </button>
                          </div>
                          <DeleteNewsletterModal 
                              id={`delete-${newsletter.id}`}
                               newsletter={newsletter}
                               newsletters={newsletters}
                               setNewsletters={setNewsletters}
                          />
                          <SelectGroupModal id={`send-${newsletter.id}`} newsletter={newsletter} />
                      </div>
                      )
                   }) : null }
               </div>               
        </div>
        <Pagination 
             newsletter_emails ={newsletters}
              getNewsletterMutation = {getNewsletterMutation} 
        />
    </section>
  )
}

export default ViewNewsletter