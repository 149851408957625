import React, {useEffect} from 'react'
import {
    Modal,
    Ripple,
    initTE,
  } from "tw-elements";
import CustomDropdown from '../../../../../components/global/CustomDrpdown';
import { useMutation } from 'react-query';
import { api } from '../../../../../api';
import ScaleLoader_ from '../../../components/Loader/ScaleLoader';
import toast from 'react-hot-toast';
  
const SelectGroupModal = ({id, newsletter}) => {

    useEffect( () => {
        initTE({ Ripple, Modal });
    }, [] )

    const sendNewsletterMutation = useMutation( api.sendNewsletters, {
        onSuccess : (data) => {
           toast.success('newsletter sent successfully!')
           document.querySelector(`#${id}-cancel-newsletter-sent-modal`).click()
        }
      })
    
  const sentNewsletter = () => {
       sendNewsletterMutation.mutate(newsletter.id)
  }

  return (
    <div
    data-te-modal-init
    className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
    id={id}
    tabIndex="-1"
    aria-labelledby="exampleModalCenterTitle"
    aria-modal="true"
    role="dialog">  { sendNewsletterMutation.isLoading && <ScaleLoader_ />}
    <div
        data-te-modal-dialog-ref
        className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
        <div
        className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
        <div
            className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">             
            <h5
            className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
            id="exampleModalCenterTitle">
               Confirm Action
            </h5>             
            <button
            type="button"
            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-te-modal-dismiss
            aria-label="Close">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6">
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
            </button>
        </div>        
        <div className="relative p-4">
             <div className='text-lg py-3 px-2'>
                 {/*<span className='text-md mr-3'>
                    Select
                 </span>
                 <CustomDropdown 
                    isMulti={false}
                    placeholder={'Select Group...'}
                    options={
                        [
                            {label: 'group 1' , value: 1},
                            {label: 'group 2' , value: 2},
                            {label: 'group 3' , value: 3},
                        ]
                    }
                />*/}
                Are you sure you want to sent <b className='text-lg px-2'>
                    {newsletter.subject}
                </b>
                to your newsletter subscribers.
             </div>
        </div>        
        <div
            className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <button
            type="button"
            id={`${id}-cancel-newsletter-sent-modal`}
            className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
            data-te-modal-dismiss
            data-te-ripple-init
            data-te-ripple-color="light">
               Cancel
            </button>
            <button
            type="button"
            className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-ripple-init
            onClick={sentNewsletter}
            data-te-ripple-color="light">
               Send
            </button>
        </div>
        </div>
    </div>
    </div>
  )
}

export default SelectGroupModal