import React, { useState, useEffect } from "react";
import { BarLoader } from "react-spinners";
import { useMutation } from "react-query";
import { api } from "../api";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useCustomToast } from "../components/customtoast/CustomToastProvider";
import { useEventCallback } from "@mui/material";

const VerifyAccount = () => {
  const [response, setResponse] = useState({
    checkStatus: false,
    isVerified: false,
    message: "",
  });
  const { mutateAsync } = useMutation(api.verifyClient);
  const { id1, id2 } = useParams();

  const customToast = useCustomToast();

  const checkVerification = async () => {
    try {
      const data = await mutateAsync({ id: id1, hash: id2 });
      setResponse((prev) => {
        return {
          ...prev,
          message: data?.message,
          isVerified: true,
          checkStatus: true,
        };
      });
    } catch ({ response }) {
      setResponse((prev) => {
        return {
          ...prev,
          message: response?.data?.message,
          isVerified: true,
          checkStatus: true,
        };
      });
    }
  };

  useEffect(() => {
    checkVerification();
  }, [id1, id2]);

  if (!response.checkStatus) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <BarLoader size={150} color={"#f35b24"} />
      </div>
    );
  }

  return (
    <div
      className="w-full flex flex-col items-center justify-center gap-3"
      style={{ height: "100vh" }}
    >
      <FontAwesomeIcon
        icon={response.isVerified ? faCircleCheck : faCircleXmark}
        className={
          response.isVerified ? "text-customGreen" : "text-customOrange"
        }
        style={{ fontSize: "50px" }}
      />
      {response.message}
    </div>
  );
};

export default VerifyAccount;
