import React, {useState, useMemo} from 'react'
import PageLayout from '../../../admin/components/PageLayout/PageLayout'
import Card from '../../../admin/components/Card/Card'
import { UpdatedRequestTable } from '../../../admin/components/Table/RequestTable'
import { useQuery } from 'react-query'
import { api } from '../../../../api/affiliate'
import Fallback from '../../../admin/components/FallBack/Fallback'
import Pagination from '../../../admin/components/Pagination/Pagination'
import VerticalMenu from '../../../../components/global/VerticalMenu'
import { useNavigate } from 'react-router-dom'
import { URL } from '../../../../urls'

const ViewLinks = () => {

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: campaings,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["get affiliate campaign", { currentPage, perPage }],
    ({ queryKey }) => {
      return api.getAffiliateCampaigns(queryKey[1]);
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data.data }
  );

  const data = campaings?.campaigns?.data || [];
  const navigate = useNavigate()

  const viewCampaignLink = row => {     
      navigate(`/affiliate/${URL.affiliate_campaign_links}?campaign-id=${row?.campaign_id}`)
  }

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((campaings?.campaigns?.current_page || 1) - 1) * (campaings?.campaigns?.per_page || perPage ),
      },
      {
        title: "Client Name",
        selector: "client_name",
        key: "client_name",
      },
      {
        title: "Title",
        selector: "title",
        key: "title",
      },
      {
        title: "Applications",
        selector: "number_of_applications",
        key: "number_of_applications",      
        render: ({ item }) => {           
          return <span className="pl-8">{item}</span>;
        },  
      },
      {
        title: "No Of Clicks",
        selector: "number_of_clicks",
        key: "number_of_clicks",
        render: ({ item }) => {           
          return <span className="pl-8">{item}</span>;
        },
      },
      {
        title: "Actions",
        selector: null,
        key: "actions",
        render: ({ row }) => {
          return (
            <VerticalMenu isBackground>
              <div>
                <button   
                  onClick={ () => viewCampaignLink(row) }               
                  className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                >
                  View Links
                </button>                 
              </div>
            </VerticalMenu>
          );
        },
      },
    ],
    [campaings?.campaigns?.current_page, campaings?.campaigns?.per_page, perPage]
  );

  const tableProps = { isLoading, data, columns };

 const paginatedProps = {
    data: campaings?.campaigns,
    perPage,
    setPerPage,
    pageNo: currentPage,
    setPageNo: setCurrentPage,
  };

  if(isLoading) return(
    <div className="bg-white w-full" style={{ height: "calc(100vh - 80px)" }}>
       <Fallback />
   </div>
)


  return (
    <PageLayout 
            className={'p-1 bg-gray-100'} backBtn={true} 
            backBtnClassname={'flex items-center justify-start mb-4 mt-5 w-full bg-white rounded px-7 py-2'}
      >  
         <section style={{ height: "calc(100vh - 80px)" }} className='bg-gray-100 overflow-y-auto'>  
           <div className=' bg-white mt-4'>             
            <section className='mt-30rounded-lg md:flex justify-center items-center pt-3 pb-5'>
              <Card 
                      title={'Total Links'}
                        body={campaings?.campaigns?.total}
                        titleClassname={'text-md uppercase'}
                />                                 
            </section>
            </div>
            <section className='mt-4 bg-white py-4'>
                <h5 className='py-5 pl-3 text-xl bg-customBlue text-white mx-3 rounded-lg'>
                     My Campaigns Links
                </h5>
                <div className='px-5'>
                   <UpdatedRequestTable {...tableProps} />
                  {<Pagination {...paginatedProps} />}
                </div>
            </section>
        </section>
    </PageLayout>
  )
}

export default ViewLinks