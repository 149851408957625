import axios from "axios";
import baseUrl from "../url";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

const createApiClient = () => {
  const key = process.env.REACT_APP_SECRET_KEY;

  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      "Cache-Control": "no-cache",
    },
  });

  client.interceptors.request.use(
    async (config) => {
      const encryptedToken = sessionStorage.getItem("userToken");

      if (encryptedToken) {
        const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, key);
        const token = decryptedToken.toString(CryptoJS.enc.Utf8);
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && error.config.url !== "/login") {
        sessionStorage.setItem("isLoggedIn", false);
        toast.remove();
        toast.error("Unauthenticated, kindly login again...");
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default createApiClient;
